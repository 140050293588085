// This file has been generated by the Scope build. Do not alter
// it manually as your changes will be overwritten. Run:
// scope.cmd frontend:build-component-registry
// instead.
/* tslint:disable */
// prettier-ignore
export default {
    Avensia_Common_Features_Account_ForgotPassword_ForgotPasswordBlock: 'Avensia.Common.Features.Account.ForgotPassword.ForgotPasswordBlock',
    Avensia_Common_Features_Account_Login_LoginBlock: 'Avensia.Common.Features.Account.Login.LoginBlock',
    Avensia_Common_Features_Account_MyPages_ChangePassword_ChangePasswordPageViewModel: 'Avensia.Common.Features.Account.MyPages.ChangePassword.ChangePasswordPageViewModel',
    Avensia_Common_Features_Account_Member_Logout_LogoutPageViewModel: 'Avensia.Common.Features.Account.Member.Logout.LogoutPageViewModel',
    Avensia_Common_Features_Account_MyPages_MyAddresses_MyAddressDetails_MyAddressDetailsViewModel: 'Avensia.Common.Features.Account.MyPages.MyAddresses.MyAddressDetails.MyAddressDetailsViewModel',
    Avensia_Common_Features_Account_MyPages_MyAddresses_MyAddressesPageViewModel: 'Avensia.Common.Features.Account.MyPages.MyAddresses.MyAddressesPageViewModel',
    Avensia_Common_Features_Account_MyPages_MyDetails_MyDetailsB2CPageViewModel: 'Avensia.Common.Features.Account.MyPages.MyDetails.MyDetailsB2CPageViewModel',
    Avensia_Common_Features_Account_MyPages_MyDetails_MyDetailsB2BPageViewModel: 'Avensia.Common.Features.Account.MyPages.MyDetails.MyDetailsB2BPageViewModel',
    Avensia_Common_Features_Account_MyPages_MyOrderDetails_MyOrderDetailsPageViewModel: 'Avensia.Common.Features.Account.MyPages.MyOrderDetails.MyOrderDetailsPageViewModel',
    Avensia_Common_Features_Account_MyPages_MyOrderDetails_OrderPageExtraTextBlock: 'Avensia.Common.Features.Account.MyPages.MyOrderDetails.OrderPageExtraTextBlock',
    Avensia_Common_Features_Account_MyPages_MyOrders_MyOrdersPageViewModel: 'Avensia.Common.Features.Account.MyPages.MyOrders.MyOrdersPageViewModel',
    Avensia_Common_Features_Account_MyPages_MyWishList_MyWishListPageViewModel: 'Avensia.Common.Features.Account.MyPages.MyWishList.MyWishListPageViewModel',
    Avensia_Common_Features_Account_Register_RegisterBlock: 'Avensia.Common.Features.Account.Register.RegisterBlock',
    Avensia_Common_Features_AppShell_Partials_AppShellViewModel: 'Avensia.Common.Features.AppShell.Partials.AppShellViewModel',
    Avensia_Common_Features_Blog_BlogArchive_BlogArchivePageViewModel: 'Avensia.Common.Features.Blog.BlogArchive.BlogArchivePageViewModel',
    Avensia_Common_Features_Blog_BlogCategory_BlogCategoryPageViewModel: 'Avensia.Common.Features.Blog.BlogCategory.BlogCategoryPageViewModel',
    Avensia_Common_Features_Blog_BlogPost_BlogPostViewModel: 'Avensia.Common.Features.Blog.BlogPost.BlogPostViewModel',
    Avensia_Common_Features_Brand_BrandListViewModel: 'Avensia.Common.Features.Brand.BrandListViewModel',
    Avensia_Common_Features_Brand_BrandViewModel: 'Avensia.Common.Features.Brand.BrandViewModel',
    Avensia_Common_Features_BulkOrder_BulkOrderPage_BulkOrderPageViewModel: 'Avensia.Common.Features.BulkOrder.BulkOrderPage.BulkOrderPageViewModel',
    Avensia_Common_Features_Campaigns_CampaignOverviewPageViewModel: 'Avensia.Common.Features.Campaigns.CampaignOverviewPageViewModel',
    Avensia_Common_Features_Campaigns_CampaignPage: 'Avensia.Common.Features.Campaigns.CampaignPage',
    Avensia_Common_Features_Category_CategoryViewModel: 'Avensia.Common.Features.Category.CategoryViewModel',
    Avensia_Common_Features_Checkout_CheckoutPage_CheckoutPageViewModel: 'Avensia.Common.Features.Checkout.CheckoutPage.CheckoutPageViewModel',
    Avensia_Common_Features_Checkout_CheckoutPage_CheckoutPageExtraTextBlock: 'Avensia.Common.Features.Checkout.CheckoutPage.CheckoutPageExtraTextBlock',
    Avensia_EpiFoundation_Shipping_CheckoutModels_ShippingMethod: 'Avensia.EpiFoundation.Shipping.CheckoutModels.ShippingMethod',
    Avensia_Common_Features_Checkout_PaymentMethods_InvoicePayment_InvoicePaymentViewModel: 'Avensia.Common.Features.Checkout.PaymentMethods.InvoicePayment.InvoicePaymentViewModel',
    Avensia_Payments_PayPal_PayPalCheckoutPaymentMethodViewModel: 'Avensia.Payments.PayPal.PayPalCheckoutPaymentMethodViewModel',
    Avensia_Payments_PayPal_PayPalCreditCardPaymentMethodViewModel: 'Avensia.Payments.PayPal.PayPalCreditCardPaymentMethodViewModel',
    Avensia_Payments_Stripe_StripePaymentMethodViewModel: 'Avensia.Payments.Stripe.StripePaymentMethodViewModel',
    Avensia_Payments_TestPayment_TestPaymentMethodViewModel: 'Avensia.Payments.TestPayment.TestPaymentMethodViewModel',
    Avensia_Common_Features_ContactForm_ContactUs_ContactUsBlockViewModel: 'Avensia.Common.Features.ContactForm.ContactUs.ContactUsBlockViewModel',
    Avensia_Common_Features_Content_ContentPageViewModel: 'Avensia.Common.Features.Content.ContentPageViewModel',
    Avensia_Common_Features_Error_Pages_PageNotFoundViewModel: 'Avensia.Common.Features.Error.Pages.PageNotFoundViewModel',
    Avensia_Common_Features_FindRetailer_Blocks_FeaturedRetailerBlock: 'Avensia.Common.Features.FindRetailer.Blocks.FeaturedRetailerBlock',
    Avensia_Common_Features_FindRetailer_Blocks_FindRetailerFilterBlock: 'Avensia.Common.Features.FindRetailer.Blocks.FindRetailerFilterBlock',
    Avensia_Common_Features_FindRetailer_Models_FindRetailerPageViewModel: 'Avensia.Common.Features.FindRetailer.Models.FindRetailerPageViewModel',
    Avensia_Common_Features_LegReader_LegReaderPageViewModel: 'Avensia.Common.Features.LegReader.LegReaderPageViewModel',
    Avensia_Common_Features_OrderConfirmation_OrderConfirmationPageViewModel: 'Avensia.Common.Features.OrderConfirmation.OrderConfirmationPageViewModel',
    Avensia_Common_Features_Pages_Article_Models_ArticlePageViewModel: 'Avensia.Common.Features.Pages.Article.Models.ArticlePageViewModel',
    Avensia_Common_Features_Pages_Campaign_Models_CampaignBrandPageViewModel: 'Avensia.Common.Features.Pages.Campaign.Models.CampaignBrandPageViewModel',
    Avensia_Common_Features_Pages_Models_ContentOverviewPageViewModel: 'Avensia.Common.Features.Pages.Models.ContentOverviewPageViewModel',
    Avensia_Common_Features_Pages_Cookies_Models_CookiesPageViewModel: 'Avensia.Common.Features.Pages.Cookies.Models.CookiesPageViewModel',
    Avensia_Common_Features_Pages_Events_Models_EventPageViewModel: 'Avensia.Common.Features.Pages.Events.Models.EventPageViewModel',
    Avensia_Common_Features_Pages_News_Models_NewsPageViewModel: 'Avensia.Common.Features.Pages.News.Models.NewsPageViewModel',
    Avensia_Common_Features_Product_ProductViewModel: 'Avensia.Common.Features.Product.ProductViewModel',
    Avensia_Common_Features_ProductListing_ProductListingPageViewModel: 'Avensia.Common.Features.ProductListing.ProductListingPageViewModel',
    Avensia_Common_Features_ResetPassword_ResetPasswordPageViewModel: 'Avensia.Common.Features.ResetPassword.ResetPasswordPageViewModel',
    Avensia_Common_Features_Search_SearchPageViewModel: 'Avensia.Common.Features.Search.SearchPageViewModel',
    Avensia_Common_Features_Shared_Blocks_Accordion_AccordionHolderBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.Accordion.AccordionHolderBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_AnchorLink_AnchorLinkBlock: 'Avensia.Common.Features.Shared.Blocks.AnchorLink.AnchorLinkBlock',
    Avensia_Common_Features_Shared_Blocks_Author_AuthorBlock: 'Avensia.Common.Features.Shared.Blocks.Author.AuthorBlock',
    Avensia_Common_Features_Shared_Blocks_Campaign_CampaignBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.Campaign.CampaignBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_CTA_CallToActionBlock: 'Avensia.Common.Features.Shared.Blocks.CTA.CallToActionBlock',
    Avensia_Common_Features_Shared_Blocks_DXCProducts_ProductListBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.DXCProducts.ProductListBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_Editor_EditorBlock: 'Avensia.Common.Features.Shared.Blocks.Editor.EditorBlock',
    Avensia_Common_Features_Shared_Blocks_Elfsight_ElfsightBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.Elfsight.ElfsightBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_EsalesProducts_ProductListBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.EsalesProducts.ProductListBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_Image_ImageBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.Image.ImageBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_ImageGallery_Models_MediaGalleryBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.ImageGallery.Models.MediaGalleryBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_Instagram_InstagramBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.Instagram.InstagramBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_Jotform_JotformBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.Jotform.JotformBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_Navigation_NavigationBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.Navigation.NavigationBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_Ostendis_OstendisBlock: 'Avensia.Common.Features.Shared.Blocks.Ostendis.OstendisBlock',
    Avensia_Common_Features_Shared_Blocks_PromoContentIn_PromoContentInBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.PromoContentIn.PromoContentInBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_RowLayout_RowLayoutBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.RowLayout.RowLayoutBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_StyleGuide_Models_StyleGuideViewModel: 'Avensia.Common.Features.Shared.Blocks.StyleGuide.Models.StyleGuideViewModel',
    Avensia_Common_Features_Shared_Blocks_Typeform_TypeformBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.Typeform.TypeformBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_Usp_UspBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.Usp.UspBlockViewModel',
    Avensia_Common_Features_Shared_Blocks_Video_VideoBlock: 'Avensia.Common.Features.Shared.Blocks.Video.VideoBlock',
    Avensia_Common_Features_Shared_ContentTeaser_ContentTeaserBlockViewModel: 'Avensia.Common.Features.Shared.ContentTeaser.ContentTeaserBlockViewModel',
    Avensia_Common_Features_Shared_GenericErrorViewModel: 'Avensia.Common.Features.Shared.GenericErrorViewModel',
    Avensia_Common_ContentTypes_Media_ImageDataModel: 'Avensia.Common.ContentTypes.Media.ImageDataModel',
    Avensia_Common_Features_Shared_Partials_PreviewViewModel: 'Avensia.Common.Features.Shared.Partials.PreviewViewModel',
    EPiServer_Core_XhtmlString: 'EPiServer.Core.XhtmlString',
    Avensia_Common_Features_Shared_SiteBanner_SiteBannerBlock: 'Avensia.Common.Features.Shared.SiteBanner.SiteBannerBlock',
    Avensia_Common_Features_Start_StartPageViewModel: 'Avensia.Common.Features.Start.StartPageViewModel',
    Avensia_Common_Features_Stores_NearestStore_NearestStoreBlockViewModel: 'Avensia.Common.Features.Stores.NearestStore.NearestStoreBlockViewModel',
    Avensia_Common_Features_Stores_StoreListPageViewModel: 'Avensia.Common.Features.Stores.StoreListPageViewModel',
    Avensia_Common_Features_Stores_StorePageViewModel: 'Avensia.Common.Features.Stores.StorePageViewModel',
    Avensia_Common_Features_Storybook_StorybookViewModel: 'Avensia.Common.Features.Storybook.StorybookViewModel',
    EPiServer_Core_ContentArea: 'EPiServer.Core.ContentArea',
    EPiServer_SpecializedProperties_LinkItemCollection: 'EPiServer.SpecializedProperties.LinkItemCollection',
};
