import { __awaiter } from "tslib";
import React from 'react';
import { styled } from '@glitz/react';
import { translate } from '@avensia/scope';
import Input, { BareInput } from 'Shared/Fields/Text';
import { average, lightGrey, large, gigantic, thick, huge, thin, createGeneralDecorator, General, eta, medium, } from 'Shared/Style';
import { DefaultButton, LinkButton, resetButtonDecorator } from 'Shared/Button';
import QuickOrderList from './List';
import { testIdProps } from 'test-automation';
import DropDown from './DropDown';
import Select from 'Shared/Fields/Select';
import { Close } from 'Shared/Icon';
import { SpinnerLoader } from 'Shared/SpinnerLoader';
import { createFeedbackSlaveButton, useFeedback } from 'Shared/Button/Feedback';
import BasicQuantity from 'Shared/BasicQuantity';
import { MAX_LIMIT_COUNT } from 'Shared/Quantity';
import { bulkOrderUrl } from 'Shared/known-urls';
import { resetLinkDecorator } from 'Shared/Generic/A';
import { useQuickOrderSearch } from './use-quickordersearch';
const BORDER_RADIUS = 30;
const COMMENT_WIDTH_COMPACT = 200;
const COMMENT_WIDTH_DESKTOP = 330;
const ORDER_LIST_HEIGHT = 330;
const SEARCH_WIDTH_COMPACT = 245;
const SEARCH_WIDTH_DESKTOP = 490;
function FullQuickOrder({ searchText, onSearchChange, onCustomerCommentChange, customerComment, quantityValue, isQuantityValid, updateIsQuantityValid, onSelectProduct, onUpdateQuantity, quickOrders, onClickAdd, showLoader, message, pairOptions, selectedPair, isHalfPairEnabled, onClickClear, onChangePair, onKeyPress, selectedOrder, isB2B, }) {
    function updateQuantity(newValue) {
        onUpdateQuantity(newValue);
        return Promise.resolve();
    }
    const [addState, pushAdd] = useFeedback();
    function onClickInternalAdd() {
        return __awaiter(this, void 0, void 0, function* () {
            const action = () => __awaiter(this, void 0, void 0, function* () {
                onClickAdd();
                return Promise.resolve();
            });
            yield pushAdd(action());
        });
    }
    const { toggleQuickOrderSearch } = useQuickOrderSearch();
    return (React.createElement(Base, null,
        React.createElement(Container, null,
            React.createElement(ActionWrapper, null,
                React.createElement(SearchInputWrapper, null,
                    React.createElement(SearchInput, Object.assign({}, testIdProps.QuickOrderSearchField, { type: "search", name: "searchphrase", autoComplete: "off", value: searchText, placeholder: translate('/QuickOrder/ArticleNumberEtc'), onChange: onSearchChange, onKeyDown: onKeyPress })),
                    React.createElement(ClearLink, { onClick: onClickClear },
                        React.createElement(ClearIcon, null))),
                isB2B && (React.createElement(CustomerCommentInput, Object.assign({}, testIdProps.QuickOrderCustomerCommentField, { type: "text", value: customerComment, placeholder: translate('/QuickOrder/CustomerComment'), onChange: onCustomerCommentChange, disabled: !selectedOrder }))),
                isHalfPairEnabled && (React.createElement(StyledWrapper, null,
                    React.createElement(Select, Object.assign({}, testIdProps.QuickOrderPairDropdown, { disabled: !selectedOrder || !(selectedOrder === null || selectedOrder === void 0 ? void 0 : selectedOrder.halfPairAvailable), value: selectedPair, options: pairOptions, onChangeOption: onChangePair, id: "dropdown" })))),
                React.createElement(QuantityWrapper, Object.assign({}, testIdProps.QuickOrdeCrQuantity),
                    React.createElement(BasicQuantity, { value: quantityValue, updateCurrentQuantity: newValue => updateQuantity(newValue), isQuantityValid: isQuantityValid, updateIsQuantityValid: updateIsQuantityValid, maxQuantity: MAX_LIMIT_COUNT, disabled: !selectedOrder })),
                React.createElement(StyledWrapper, null,
                    React.createElement(AddButton, Object.assign({}, testIdProps.AddQuickOrder, { disabled: !selectedOrder || !isQuantityValid, onClick: onClickInternalAdd, state: addState }), translate('/QuickOrder/Add')))),
            React.createElement(ActionWrapper, null,
                React.createElement(UploadFileWrapper, null,
                    React.createElement(UploadLink, { to: bulkOrderUrl(), onClick: toggleQuickOrderSearch }, translate('/BulkOrder/QuickOrderUploadList')))),
            showLoader && (React.createElement(SpinnerWrapper, null,
                React.createElement(SpinnerLoader, null))),
            (quickOrders === null || quickOrders === void 0 ? void 0 : quickOrders.length) > 0 ? (React.createElement(OrderListWrapper, null,
                React.createElement(DropDown, { isOpen: true },
                    React.createElement(QuickOrderList, { open: true, orders: quickOrders, orderSearchText: searchText, onSelectOrder: (order) => onSelectProduct(order) })))) : (message && !showLoader && React.createElement(Message, null, message)))));
}
export default styled(FullQuickOrder);
const Base = styled.div({
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
});
const SpinnerWrapper = styled.div({
    marginTop: gigantic,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
});
const Message = styled(SpinnerWrapper, {
    justifyContent: 'flex-start',
});
const Container = styled.div({
    width: '100%',
});
const ActionWrapper = styled.div({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});
const SearchInputWrapper = styled.div({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'baseline',
    justifyContent: 'space-between',
});
const ClearLink = styled(LinkButton, resetButtonDecorator, {
    position: 'absolute',
    right: ({ isCompact }) => (isCompact ? '10%' : gigantic + thick),
    top: ({ isCompact }) => (isCompact ? '38%' : '40%'),
});
const ClearIcon = styled(Close, {
    width: average - thin,
    height: average - thin,
    position: 'absolute',
    right: '5%',
    top: '25%',
    bottom: '5%',
});
const CustomerCommentInput = styled(Input, {
    width: ({ isCompact }) => (isCompact ? COMMENT_WIDTH_COMPACT : COMMENT_WIDTH_DESKTOP),
    marginRight: ({ isCompact }) => (isCompact ? eta : large),
});
const SearchInput = createGeneralDecorator(General.Default)(styled(BareInput, {
    marginRight: ({ isCompact }) => (isCompact ? eta : large),
    padding: {
        left: large,
        right: huge,
        y: average,
    },
    backgroundColor: lightGrey,
    borderRadius: BORDER_RADIUS,
    width: ({ isCompact }) => (isCompact ? SEARCH_WIDTH_COMPACT : SEARCH_WIDTH_DESKTOP),
}));
const OrderListWrapper = styled.div({
    width: '100%',
    overflowY: 'scroll',
    height: ORDER_LIST_HEIGHT,
    marginTop: huge + thick,
});
const StyledWrapper = styled.div({
    marginRight: ({ isCompact }) => (isCompact ? eta : large),
});
const QuantityWrapper = styled.div({
    marginRight: medium,
});
const AddButton = styled(createFeedbackSlaveButton(DefaultButton), {
    border: { bottom: { style: 'none' } },
});
const UploadFileWrapper = styled.div({
    margin: { xy: large },
    fontWeight: 600,
});
const UploadLink = styled(LinkButton, resetLinkDecorator({ color: theme => theme.linkColor, textDecoration: 'none' }), {
    marginBottom: large,
    ':focus': {
        textDecoration: 'none',
    },
});
